p{
    margin-top: 8px;
  }

 
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #5255f1;
    transition: 0.4s;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #474af7;
  }

  