.mapouter {
  position: relative;
  text-align: right;
  height: 600px;
  width: 838px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height:100%;
  width: 100%;
}
