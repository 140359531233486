.slider-container .slider {
  position: relative;
  top: 0;
  height: 82vh;
  width: 100vw;
  overflow: hidden;
}
@media only screen and (max-width: 775px) {
  .slider-container .slider {
    height: 50vh;
    width: 100vw;
  }
}
.slider-container .slider .carousel-image {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  will-change: transform, opacity;
}
.slider-container .slider .carousel-image:hover {
  cursor: grab;
}
.slider-container .slider .carousel-image:active {
  cursor: grabbing;
}
.slider-container .button-container {
  display: flex;
  width: 100vw;
  position: relative;
  top: 50%;
  justify-content: space-between;
  padding: 0 25px;
}
.slider-container .button-container button {
  background: #fff;
  left: 5px;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  opacity: 0.6;
  transition:  300ms;
}
.slider-container .button-container button:hover{
  scale: 1.2;
}
.thumbnails {
  display: flex;
  justify-content: center;
}
.thumbnails .thumbnail-container {
  position: relative;
  height: 120px;
  width: 90px;
}
@media only screen and (max-width: 375px) {
  .thumbnails .thumbnail-container {
    height: 70px;
    width: 50px;
  }
}
.thumbnails .thumbnail-container:hover {
  cursor: pointer;
}
.thumbnails .thumbnail-container:not(:last-of-type) {
  margin-right: 5px;
}
.thumbnails .thumbnail-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.thumbnails .thumbnail-container .active-indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  transform: scaleX(0);
  transform-origin: left;
  background-color: #1f1f1f;
  transition: 1s cubic-bezier(0.56, 0.03, 0.12, 1.04) transform;
}
.thumbnails .thumbnail-container .active-indicator.active {
  transform: scaleX(1);
}
