.home-image-slide {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.home-image-slide img {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.welcome-title {
  font-family: "Times New Roman", Times, serif;
}
